import { makeOrderedListComparitor } from "../../utils/sort";
import { MembershipRole } from "./Team";
import { EDITION_ORDER } from "./Team.consts";
import { ReclaimEdition } from "./Team.types";

export const reclaimEditionComparitor = makeOrderedListComparitor(EDITION_ORDER);
export const membershipRoleComparitor = makeOrderedListComparitor([MembershipRole.Admin, MembershipRole.User]);

export const canChangeSettings = (role: MembershipRole | undefined): boolean => role === MembershipRole.Admin;
export const canInvite = (role: MembershipRole | undefined, userInviteLevel: ReclaimEdition | undefined): boolean =>
  canChangeSettings(role) || userInviteLevel !== "NONE";


